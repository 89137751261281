<template>
  <b-row class="auth-wrapper auth-v2">
    <b-col class="w-100">
      <div class="w-100 d-flex justify-content-between">
        <span class="text-proposals">{{ $t('creator.proposalsFeatured') }}</span>
        <b-link href="https://app.brandme.la/search-proposal" target="_blank">
          <span class="avenir-medium">{{ $t('creator.viewAlls') }}</span>
        </b-link>
      </div>
      <component :is="vue_horizontal" responsive class="horizontal my-2" :displacement="0.3" snap="center">
        <card-category
          v-for="(item, index) in results" :key="index"
          :proposal="item"
          :redirect="true"
          :width="'300px'"
          :countries="countries"
          class="mr-1"
        />
      </component>
    </b-col>

    <b-col class="col-12">
      <div class="mb-2">
        <span class="text-proposals">{{ $t('creator.proposalsBynet') }}</span>
      </div>
      <component :is="vue_horizontal" responsive class="horizontal" :displacement="0.3" snap="center">
        <card-network  v-for="(item, index) in network"
          :key="index"
          :net="item"
          :open_new_tab="true"
          :icon="getIconWhite(item)"
          :img="imgNetwork(item)"
        />
      </component>
    </b-col>
    <b-col class="col-12 mb-2">
      <brandme-ia-banner :open_new_tab="true"></brandme-ia-banner>
    </b-col>

    <b-col class="col-12 px-1 mb-2">
      <div class="box-casting-calls">
        <div class="col-12 col-md-3 mb-1 mb-md-0">
          <h1>{{ $t('dashboard.campaigns') }}</h1>
          <p class="avenir-medium">{{ $t('creator.wantWork') }}</p>
          <b-button variant="light" class="avenir-medium" @click="openToTab">{{ $t('creator.viewMore') }}</b-button>
        </div>
        <div class="col-12 col-md-9">
          <component :is="vue_horizontal" responsive class="horizontal horizontal-gray-casting-calls" :displacement="0.3" snap="end" v-if="loaded_casting_calls">
            <card-casting-call v-for="casting_call in casting_calls" :key="casting_call.uuid" :casting_call="casting_call" :redirect="true"></card-casting-call>
          </component>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton
} from "bootstrap-vue";
import cardCategory from "@/views/pages/proposals/cardCategory.vue";
import cardNetwork from "@/views/pages/proposals/cardNetwork.vue";
import cardCastingCall from '@/views/components/casting-calls/cardCastingCall.vue';
import service from "@/services/proposals";
import { getMotivationalPhrase, getCountries } from '@/libs/utils/others';
import { getIconWhite } from '@/libs/utils/icons';
import { getUrlCategory } from '@/libs/utils/urls';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'wordpressProposals',
  components: {
    BRow,
    BCol,
    BLink,
    cardCategory,
    cardNetwork,
    cardCastingCall,
    BButton,
    BrandmeIaBanner: () => import('@/views/components/banner/BrandmeIaBanner.vue')
  },
  data () {
    return {
      getIconWhite,
      results: {},
      countries: getCountries(),
      vue_horizontal: null,
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat'],
      loaded_casting_calls: false,
      casting_calls: []
    }
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default
  },
  created () {
    this.getAll();
    this.getCastingCalls();
  },
  methods: {
    openToTab() {
      const url = this.$router.resolve({ name: 'casting_calls' }).href;
      window.open(url, '_blank');
    },
    getCastingCalls() {
      casting_calls_services.getCastingCalls({}, 8, '1', false).then((response) => {
        this.casting_calls = response.results;
        this.loaded_casting_calls = true;
      })
    },
    imgNetwork(category) {
      return getUrlCategory(category)
    },
    getAll() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });
      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.getFavoriteProposals().then((response) => {
        this.results = response.results
        this.loaded = true;
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
      });
    },
  }
}
</script>
<style>
.tranparent-background-nav {
  background: none !important;
  background-color: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.in-animation-nav {
  animation: nav-animation 1s;
}
.out-animation-nav {
  animation: nav-animation-2 1s;
}
@keyframes nav-animation {
  0% { transform: translateY(0px);}
  50% {transform: translateY(-100px);}
  100% {transform: translateY(0px);}
}
@keyframes nav-animation-2 {
  0% { transform: translateY(0px);}
  50% {transform: translateY(-100px);}
  100% {transform: translateY(0px);}
}
.leave {
  position: absolute;
}
</style>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 4em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.box-casting-calls {
  background-color: #ececec;
  padding: 2em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-prev {
  background: linear-gradient(to right, #ececec 0, #ffffff00 66%, #ffffff00) !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #ececec 66%, #ececec) !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
.link-view-all {
  font-weight: 400;
  color: #55a6c4 !important;
}
.text-proposals {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}
.container-proposals {
  display: flex;
  justify-content: space-between !important;
  margin: 67px 10px 10px 8px;
}
.container-cards {
  display: flex;
  justify-content: space-between !important;
}
.button-create {
  margin: 22px;
  background: rgba(248, 249, 250, 0.2) !important;
  color: #f8f9fa;
}
.button-create:hover {
  margin: 22px;
  background: rgba(248, 249, 250, 0.792) !important;
}
.icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  margin-top: -41px;
  margin-left: 3px;
  font-size: 40px;
  cursor: pointer;
  padding: 8px;
}
.container-search .input-search-proposal { 
  border-radius: 1.357rem;
  padding-left: 40px;
  width: 550px;
  height: 45px;
}
.text-title {
  position: relative;
  font-size: calc(1.409375rem + 1.9125vw);
  font-weight: bold;
  line-height: 1.2;
}
.landing-cover {  
  position: absolute;
  top: 8.5rem;
  width: 100%;
  background: transparent;
}
.background-video-proposals {
  width: 100%;
  height: 440px;
  object-fit: fill;
}
@media (max-width: 615px) {
 .container-search .input-search-proposal{ 
   display: flex;
   width: 100%;
 }
 @media (max-width: 459px) {
 .container-cards { 
   justify-content: center !important;
  }
 }
}
.container-results-proposals {
  max-width: 1440px;
}
.card-provisional {
  /* width: 100px !important; */
  background-color: red;
  padding: 1em;
  margin: 1em;
}
</style>